<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4">
        <date-interval name="Дата создания" :valueFrom.sync="createdFrom" :valueTo.sync="createdTo" valueFromMinus="month:1" />

        <date-interval name="Дата обновления" :valueFrom.sync="updatedFrom" :valueTo.sync="updatedTo"/>

        <direction-autocomplete-multiple :select.sync="directions" class="mb-4 mt-5"/>

        <checkbox-field name="Скрыть прошедшие валидацию" class="mb-4" :value.sync="hideValidated"/>

        <checkbox-field name="Показать удалённые" class="mb-4" :value.sync="showDeleted"/>
  
        <v-btn v-show="servicesSelects.length>0" block depressed color="silver" @click="serviceValidation">
            Верифицировать
        </v-btn>
      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
        ref="table"
        name="Список"
        :genRequestBuilder="genRequestBuilder"
        :height="height"
        :headers="[
          { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap', sortable: true},
          { text: 'Название', value: 'name', class:'text-no-wrap', sortable: true},
          { text: 'Создано', value: 'created', class:'text-no-wrap', sortable: true},
          { text: 'Изменено', value: 'updated', class:'text-no-wrap', sortable: true},
          { text: 'Валидация', value: 'validate', class:'text-no-wrap', sortable: true},
          { text: 'Направление', value: 'directions', class:'text-no-wrap', sortable: true },
          { text: '', value: 'sort', class:'text-no-wrap', sortable: false },
          { text: 'Сорт.', value: 'sort', class:'text-no-wrap', sortable: true },
          { text: 'Внеш. код', value: 'isid', class:'text-no-wrap', sortable: false },
          { text: 'Код', value: 'code', class:'text-no-wrap', sortable: false },
          { text: 'Длительность', value: 'duration', class:'text-no-wrap', sortable: false },
          { text: 'Срок исп.', value: 'resultDuration', class:'text-no-wrap', sortable: false },
          { text: 'План (с/по)', value: 'startDay', class:'text-no-wrap', sortable: false },
          { text: 'Цена', value: 'priceTotal', class:'text-no-wrap', sortable: true },
        ]"
        :selectAll.sync="servicesSelectAll"
        :selectIndeterminate="servicesSelectIndeterminate"
      >      
    
      <template v-slot:item="{ item }">
              <tr

                  class="clickable"
                  @click="item.serviceCatId = item.serviceCat.id; serviceCreateUpdateState.modalOpen(item)"
                  @contextmenu.prevent="serviceCreateUpdateState.modalOpen(item)"
                  :class="{_priceDeleted: item.status==='deleted'}"
              >
              <td>
                  <v-checkbox @click.stop="$tools.selectToggle(servicesSelects, item)" :input-value="$tools.isSelect(servicesSelects, item)" class="ma-0 pa-0" dense hide-details />
                </td>
                <td>{{item.id}}</td>
                <td class="serviceName">
                  <tooltip-view v-if="!$tools.isEmpty(item.comBranch)" top :content="item.comBranch.name">
                    <template v-slot:action>
                      <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/>   
                    </template>
                  </tooltip-view>
                  <tooltip-view v-if="item.type==='serviceComposite'" top content="Составная услуга">
                    <template v-slot:action>
                      <v-icon class="mr-2" size="18" style="margin-top:-1px">mdi-label-multiple-outline</v-icon>
                    </template>
                  </tooltip-view>
                  <tooltip-view v-if="item.type==='product'" top content="Товар">
                    <template v-slot:action>
                      <v-icon class="mr-2" size="16">mdi-basket-outline</v-icon>
                    </template>
                  </tooltip-view>
                  {{item.name}}
                </td>
                <td>{{ $tools.date(item.created)}}</td>
                <td>{{ $tools.date(item.updated)}}</td>
                <td>
                  <v-chip
                    v-if="item.validate"
                    color="green"
                    text-color="white"
                    small
                  >
                  {{ $tools.date(item.validate)}}
                  </v-chip>
                  <span v-else>-</span>
                </td>
                <td>
                  <span v-html="directionNames(item.directions)"></span>
                </td>
                <td>
                  <tooltip-view v-if="item.isShowForClient" bottom content="Отображать на сервисах (сайт, моб. приложение)">
                    <template v-slot:action>
                      <v-icon small color="grey" class="mr-2">mdi-eye</v-icon>
                    </template>
                  </tooltip-view>
                  <tooltip-view v-if="item.isVisitCreateForClient" bottom content="Возможность самозаписи клиентом">
                    <template v-slot:action>
                      <v-icon small color="grey" class="mr-2">mdi-account-check</v-icon>
                    </template>
                  </tooltip-view>
                  <tooltip-view v-if="item.isPriceLock" bottom content="Скидка запрещена">
                    <template v-slot:action>
                      <v-icon small color="grey" class="mr-2">mdi-cash-lock</v-icon>
                    </template>
                  </tooltip-view>
                </td>
                <td>{{item.sort}}</td>
                <td>{{item.isid}}</td>
                <td>
                  <service-code-view :code="item.code"/>
                </td>
                <td class="text-right">{{item.duration}} мин.</td>
                <td class="text-right">{{item.resultDuration}} д.</td>
                <td class="text-right">{{item.startDay==null ? '-' : item.startDay+'д.'}} {{item.endDay==null ? '' : ' / ' + item.endDay+'д.'}}</td>                   
                <td class="text-right">{{$tools.price(item.priceTotal)}}</td>
              </tr>
        </template>
      </n-table-view>
      <service :state="serviceCreateUpdateState" @createdUpdatedDeleted="$refs.table.load()" />
    </template>
  </layout-view>
</template>

<script>

import Api from "@/Api";
import State from "@/plugins/state";
import LayoutView from "@/components/LayoutView";
import NTableView from "@/componentsV2/base/NTableView.vue";
import moment from "moment";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import {Entity_Service, Entity_Direction, Entity_DirectionCat, Entity_Spec, Entity_Nomen, Entity_ComBranch, Entity_ServiceCat} from "../../../EntityStoreCacheService";
import TooltipView from "@/components/TooltipView";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";
import Service from "@/views/Service/Service";
import ServiceCodeView from "@/views/Service/ServiceCodeView";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

export default {
  components: {
    NTableView,
    LayoutView,
    DateInterval,
    TooltipView,
    DirectionAutocompleteMultiple,
    Service,
    ServiceCodeView,
    CheckboxField
  },
  props: ['currentUser'],
  data:()=>({
    createdFrom:null,
    createdTo:null,
    updatedFrom:moment().subtract(7,'days').format('DD.MM.YYYY'),
    updatedTo: moment().format('DD.MM.YYYY'),
    directions: [],
    serviceCreateUpdateState: new State(),
    services:[],
    servicesSelects:[],
    hideValidated: true,
    showDeleted: false
  }),
  computed: {
    directionNames() {
      return (items) => {
        if (items.length>0) {
          let directions = [];
          items.forEach(i => {
            directions.push(i.name);
          });
          return `${directions.join()}`;
        } else return '-';
      }
    },
    servicesSelectAll:{
      get(){
        return this.$tools.isSelectAll(this.services, this.servicesSelects)
      },
      set(){
        this.$tools.selectAllToggle(this.services, this.servicesSelects)
      }
    },
    servicesSelectIndeterminate:{
      get(){
        return this.$tools.isSelectIntermidiate(this.services, this.servicesSelects)
      },
    },
  },
  watch:{
    createdFrom(){
      this.$refs.table.load();
    },
    createdTo(){
      this.$refs.table.load();
    },
    updatedFrom(){
      this.$refs.table.load();
    },
    updatedTo(){
      this.$refs.table.load();
    },
    directions() {
      this.$refs.table.load();
    },
    hideValidated() {
      this.$refs.table.load();
    },
    showDeleted() {
      this.$refs.table.load();
    }
  },
  methods:{
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_Service()
        .selects(Entity_Service.id)
        .selects(Entity_Service.code)
        .selects(Entity_Service.comment)
        .selects(Entity_Service.commentForClient)
        .selects(Entity_Service.created)
        .selects(Entity_Service.des)
        .selects(Entity_Service.duration)
        .selects(Entity_Service.endDay)
        .selects(Entity_Service.isCompositePrice)
        .selects(Entity_Service.isPriceLock)
        .selects(Entity_Service.isShowForClient)
        .selects(Entity_Service.isVisitCreateForClient)
        .selects(Entity_Service.isid)
        .selects(Entity_Service.name)
        .selects(Entity_Service.optionsJson)
        .selects(Entity_Service.priceExec)
        .selects(Entity_Service.priceExpend)
        .selects(Entity_Service.priceTotal)
        .selects(Entity_Service.resultDuration)
        .selects(Entity_Service.sort)
        .selects(Entity_Service.startDay)
        .selects(Entity_Service.status)
        .selects(Entity_Service.type)
        .selects(Entity_Service.updated)
        .selects(Entity_Service.validate)

        // serviceCat
        .selects([Entity_Service.serviceCat, Entity_ServiceCat.id].join('.'))

        //nomen
        .selects([Entity_Service.nomen, Entity_Nomen.id].join('.'))
        .selects([Entity_Service.nomen, Entity_Nomen.name].join('.'))
   

        //comBranch
        .selects([Entity_Service.comBranch, Entity_ComBranch.id].join('.'))
        .selects([Entity_Service.comBranch, Entity_ComBranch.name].join('.'))
        .selects([Entity_Service.comBranch, Entity_ComBranch.color].join('.'))      

        //spec
        .selects([Entity_Service.spec, Entity_Spec.id].join('.'))
        .selects([Entity_Service.spec, Entity_Spec.name].join('.'))
        .selects([Entity_Service.spec, Entity_Spec.color].join('.'))

        //cServices
        .selects([Entity_Service.cServices, Entity_Service.id].join('.'))
        .selects([Entity_Service.cServices, Entity_Service.name].join('.'))
        .selects([Entity_Service.cServices, Entity_Service.spec, Entity_Spec.id].join('.'))
        .selects([Entity_Service.cServices, Entity_Service.spec, Entity_Spec.name].join('.'))
        .selects([Entity_Service.cServices, Entity_Service.spec, Entity_Spec.color].join('.'))

        //directions
        .selects([Entity_Service.directions, Entity_Direction.id].join('.'))
        .selects([Entity_Service.directions, Entity_Direction.name].join('.'))
        .selects([Entity_Service.directions, Entity_Direction.directionCat, Entity_DirectionCat.id].join('.'))

        rb.page(page).onPage(onPage)

        if(this.createdFrom) {  
          rb.filterAnd([Entity_Service.entity, Entity_Service.created].join('.')+'>=?',moment(this.createdFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
        } 

        if(this.createdTo) {  
          rb.filterAnd([Entity_Service.entity, Entity_Service.created].join('.')+'<=?',moment(this.createdTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
        }

        if(this.updatedFrom) {  
          rb.filterAnd([Entity_Service.entity, Entity_Service.updated].join('.')+'>=?',moment(this.updatedFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
        } 

        if(this.updatedTo) {  
          rb.filterAnd([Entity_Service.entity, Entity_Service.updated].join('.')+'<=?',moment(this.updatedTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
        }

        if (this.directions.length>0) {
          rb.filterAnd([Entity_Service.entity, Entity_Service.directions, Entity_Direction.id].join('.')+' in (?)', this.queryArray(this.directions))
        }

        if(this.hideValidated) {
          rb.filterAnd([Entity_Service.entity, Entity_Service.validate].join('.')+' is null');
        }

        if (!this.showDeleted) {
          rb.filterAnd([Entity_Service.entity, Entity_Service.status].join('.')+'!= ?', 'deleted');
        }
        
        rb.addStateCallback((v)=>{
          this.services = v.items
        })
        

        sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
        })

        return rb;
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    },
    serviceValidation() {
      if (this.servicesSelects.length>0) {
        let servicesIds = [];
        this.servicesSelects.forEach((s)=>{
          servicesIds.push(s.id);
        });

        Api.service.service.validation({service:servicesIds},() => {
          this.servicesSelects = [];
          this.$refs.table.load();
        }, (e) => {
          console.log(e);
        });
      }
    }
  }
}
</script>